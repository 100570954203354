.app {
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: '100vh';
    font-family: 'Poppins';
}

.app.undefined {
    background-image: url('./images/undefined.jpg');
}

.app.snow {
    background-image: url('./images/snow.jpg');
}

.app.clouds {
    background-image: url('./images/clouds.jpg');
}

.app.mist {
    background-image: url('./images/mist.jpg');
}

.app.clear {
    background-image: url('./images/clear.jpg');
}

.app.rain {
    background-image: url('./images/rain.jpg');
}

.app.fog {
    background-image: url('./images/fog.jpg');
}